<template>
  <div class="work-book-view">
    <div class="user-content">
      <h2>{{i18n.Word.title}}</h2>
      <div class="list">
        <div v-for="(item, index) of userlist" :key="index" class="list-item">
          <div v-if="editId === item.id" class="edit-content">
            <el-input maxlength="8" size="mini" placeholder="最多8个字" class="edit-input" v-model="item.name"/>
            <el-button size="mini" plain round class="edit-btn" @click.stop="handleEdit(item)">{{i18n.Word.modify}}</el-button>
          </div>
          <h3 v-else>{{item.name}}</h3>
          <div class="num">{{item.word_cnt}}{{i18n.Word.word}}</div>
          <div class="progress">
            <el-progress :percentage="item.rate" :color="'#FFC06A'" :show-text="false" :stroke-linecap="'square'" :stroke-width="10"/>
            <div class="text">
              <span>{{i18n.Word.Mastered}}</span>
              <span>{{item.know_cnt}}/{{item.word_cnt}}</span>
            </div>
          </div>
          <div class="click-content" v-if="editId !== item.id" @click.self="goLearn(item.id, 'user')"></div>
          <el-button round size="medium" type="info" plain class="learn-btn" @click="goLearn(item.id, 'user')">{{i18n.Word.learn}}</el-button>
          <el-dropdown @command="handleCommand" trigger="click" v-if="item.id !== defaultId">
            <span class="el-dropdown-link">
              <i class="f-icon icon-zu3829 more-btn"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="work-book-dropdown">
              <el-dropdown-item :command="{id: item.id, command: 'edit'}">{{i18n.Word.rename}}</el-dropdown-item>
              <el-dropdown-item :command="{id: item.id, command: 'del'}">{{i18n.Word.delete}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="list-item add-btn" @click="handleAdd" v-if="userlist.length < 6">
          <i class="f-icon icon-guanbi"></i>
        </div>
      </div>
    </div>
    <div class="base-content">
      <h2>{{i18n.Word.library}}</h2>
      <div class="list">
        <div v-for="(item, index) of baseList" :key="index" class="list-item" @click="goLearn(item.id, 'base')">
          <h3>{{item.name}}</h3>
          <div class="num">{{item.word_cnt}}{{i18n.Word.word}}</div>
          <div class="progress">
            <el-progress :percentage="item.rate" :color="'#FFC06A'" :show-text="false" :stroke-linecap="'square'" :stroke-width="10"/>
            <div class="text">
              <span>{{i18n.Word.Mastered}}</span>
              <span>{{item.know_cnt}}/{{item.word_cnt}}</span>
            </div>
          </div>
          <el-button round size="medium" type="info" class="learn-btn">{{i18n.Word.learn}}</el-button>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="330px" custom-class="work-book-new-dialog">
      <h4>{{i18n.Word.NewWordbook}}</h4>
      <el-input maxlength="8" size="mini" placeholder="最多8个字" class="edit-input" v-model="newName"/>
      <span slot="footer" class="dialog-footer">
        <el-button round size="medium" type="info" plain class="close-btn" @click="dialogVisible = false">{{i18n.Word.cancel}}</el-button>
        <el-button round size="medium" type="info" class="sure-btn" @click="handleSave">{{i18n.Word.determine}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  apiBaseWordType,
  apiWordTypeList,
  apiWordTypeEdit,
  apiWordTypeDel,
  apiWordTypeAdd
} from '@/api/api.js'

export default {
  data() {
    return {
      baseList: [],
      userlist: [],
      editId: '',
      dialogVisible: false,
      newName: '',
      defaultId: ''
    }
  },
  created() {
    this.fetchBaseWordType()
    this.fetchUserWordType()
  },
   computed: {
    i18n() {
      return this.$t('messages')
    },
  },
  methods: {
    fetchBaseWordType() {
      apiBaseWordType().then((res) => {
        if (res.code !== 200) {
          return
        }
        this.baseList = res.data.map(item => ({...item, rate: item.word_cnt ? item.know_cnt/item.word_cnt * 100 : 0}))
      })
    },
    fetchUserWordType() {
      apiWordTypeList().then((res) => {
        if (res.code !== 200) {
          return
        }
        const defaultItem = res.data.find(item => item.name === '默认') || {}
        this.defaultId = defaultItem.id
        this.userlist = res.data.map(item => ({...item, rate: item.word_cnt ? item.know_cnt/item.word_cnt * 100 : 0}))
      })
    },
    handleCommand({command, id}) {
      if (command === 'edit' && !this.editId) {
        this.editId = id
      }
      if (command === 'del') {
        this.handleDel(id)
      }
    },
    handleAdd() {
      this.dialogVisible = true
      this.newName = ''
    },
    handleEdit({name, id}) {
      if (!name) {
        return
      }
      apiWordTypeEdit({ id, name }).then((res) => {
        if (res.code !== 200) {
          return
        }
        this.$layer.msg(res.msg)
        this.editId = ''
        this.fetchUserWordType()
      })
    },
    handleDel(id) {
       this.$confirm(
        `<div><div style="position: absolute; top: -30px; color: #A4AFB7;">删除</div><p style="color: #1F1F40; padding: 16px 0 10px; border-top: 1px solid #EBEBF2;">删除将会清空所有单词，确认删除吗？</p></div>`,
        '',
        {
          customClass: 'del-word-type-dialog',
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          callback: (action) => {
            if (action === 'confirm') {
              apiWordTypeDel({ id }).then((res) => {
                if (res.code !== 200) {
                  return
                }
                this.$layer.msg(res.msg)
                if (this.editId = id) {
                  this.editId = ''
                }
                this.fetchUserWordType()
              })
            }
          }
        }
      )
    },
    handleSave() {
      if (!this.newName) {
        return
      }
      this.dialogVisible = false
      apiWordTypeAdd({ name: this.newName }).then((res) => {
        if (res.code !== 200) {
          return
        }
        this.$layer.msg(res.msg)
        this.fetchUserWordType()
      })
    },
    goLearn(id, type) {
      this.$router.push({ name: 'wordList', params: {id}, query: {type} })
    }
  }
}
</script>

<style lang="scss">
.work-book-new-dialog {
  border-radius: 10px;
  text-align: center;
  h4 {
    font-size: 16px;
    font-weight: normal;
    color: #1F1F40;
    margin-bottom: 12px;
  }
  .el-input {
    width: 170px;
    font-size: 16px;
  }
  .el-input__inner {
    padding: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    &:focus {
      border-color: #3B86FF;
    }
  }
  .el-dialog__footer {
    text-align: center;
    padding-bottom: 39px;
  }
  .el-dialog__body {
    padding: 10px 20px 15px 20px !important;
  }
  .el-button {
    width: 100px;
    border: none;
  }
  .sure-btn {
    color: #FFFFFF;
    background: #3B86FF;
    &:hover,&:focus {
      color: #FFFFFF;
      background: #3B86FF;
    }
  }
  .close-btn {
   color: #1F1F40;
    &:hover,&:focus {
      color: #3B86FF;
      background: #C9E2FF;
    }
  }
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: #3B86FF;
  }
}
.work-book-dropdown {
  background: rgba(58, 58, 58, 0.8);
  .el-dropdown-menu__item {
    color: #fff;
    &:hover {
      background-color: transparent !important;
      color: #fff !important;
    }
  }
  &.el-popper[x-placement^=bottom] .popper__arrow::after {
    border-bottom-color: rgba(58, 58, 58, 0.8);
    top: 0px;
  }
}
.work-book-view {
  color: #1F1F40;
  h2 {
    font-size: 18px;
    margin-top: 10px;
  }
  .edit-content {
    position: relative;
    width: 170px;
    .edit-input {
      .el-input__inner {
        padding: 0;
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
        &:focus {
          border-color: #3B86FF;
        }
      }
    }
    .edit-btn {
      position: absolute;
      right: 0;
      color: #3B86FF;
      background: #C9E2FF;
      border: none;
      &:hover,&:focus {
        color: #FFFFFF;
        background: #3B86FF;
      }
    }
  }

  .user-content {
    .list-item {
      border: 1px solid #C9E2FF;
      box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
      .learn-btn {
        color: #1F1F40;
        &:hover,&:focus {
          color: #3B86FF;
          background: #C9E2FF;
        }
      }
    }
  }
  .base-content {
    .list-item {
      background: #4A4A58;
      border: 1px solid #4A4A58;
      box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
      h3 {
        color: #FFFFFF;
      }
      .num {
        color: #9C9C9C;
      }
      .progress {
        color: #9C9C9C;
        .el-progress-bar__outer {
          background-color: rgba(255, 255, 255, 0.5)
        }
      }
      .learn-btn {
        color: #FFFFFF;
        background: rgba(255, 255, 255, 0.2);
        &:hover,&:focus {
          color: #FFFFFF;
          background: #3B86FF;
        }
      }
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -1.16% 0;
    .list-item {
      display: flex;
      margin: 0 1.16% 20px;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px 32px 28px 32px;
      width: 31%;
      height: 180px;
      border-radius: 10px;
      box-sizing: border-box;
      position: relative;
      transition: 0.1s all ease;
      &:hover {
        transform: translateY(-3px);
      }
      h3 {
        font-size: 20px;
        font-weight: normal;
      }
      .num {
        color: #9C9C9C;
        font-size: 14px;
        margin-bottom: 5px;
      }
      .learn-btn {
        position: absolute;
        right: 32px;
        bottom: 28px;
        border: none;
      }
      .progress {
        width: 170px;
        color: #9C9C9C;
        font-size: 12px;
        .text {
          display: flex;
          justify-content: space-between;
          margin-top: 15px;
        }
      }
      .el-dropdown {
        position: absolute;
        top: 30px;
        right: 16px;
        .more-btn {
          color: #DCDCDC;
        }
      }
      .click-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
    .add-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border: 2px dashed #DCDCDC;
      cursor: pointer;
      &:hover {
        border: 2px dashed #DCDCDC;
      }
      i {
        color: #DCDCDC;
        font-size: 34px;
      }
    }
  }
}
.del-word-type-dialog {
  border-radius: 10px;
  .el-button--primary {
    background: #3b86ff;
    border-color: #3b86ff;
    border-radius: 5px;
    padding: 4px 18px;
  }
  .el-message-box__header {
    padding-top: 33px;
  }
  .el-message-box__content {
    padding: 0 15px;
  }
  .el-button:not(.el-button--primary) {
    color: #3B86FF;
    padding: 4px 18px;
    border: none;
    .f-icon {
      color: #7c8084;
    }
    &.active,
    &:hover {
      color: #3b86ff;
      background: #ecf3ff;
      border-color: #3b86ff;
      .f-icon {
        color: #3b86ff;
      }
    }
    &:focus {
      &.active,
      &:hover {
        color: #3b86ff;
        background: #ecf3ff;
        border-color: #3b86ff;
        .f-icon {
          color: #3b86ff;
        }
      }
      color: #7c8084;
      background: #fff;
      border: 1px solid #dcdfe6;
    }
  }
}
</style>